import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="404: Not Found" />
        <section id="intro" className="padding">
          <h2 className="slogan">
            <span className="werken">Werken in het onderwijs.</span> De baan van
            het leven.
          </h2>
          <p className="subtitle">
            Onderwijs is het enige vak ter wereld waar letterlijk alle aspecten
            van het leven voorbijkomen. Samen met je collega’s leg je de basis
            voor de levens van jonge mensen. En dat is net zo mooi,
            confronterend, dankbaar, spannend, simpel en soms ingewikkeld, als
            het leven zelf.
          </p>
        </section>
        <h1>Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
